'use client';

import { review } from "@/types/graphql";
import Rating from "@/ui-components/rating";
import { stdComponentDataList } from "@/utils/std-component-data";
import { useState } from "react";

//-->dynamicData
let dynamicData = {
	uid: 'legacy-pages-reviews',
	adapter: "reviews",
	data: {

		"all_reviews": {
			sort: 2,
			name: 'Show All Reviews',
			type: 'checkbox',
			value: 'true',
			section: 'content',
		},
		"reviews_number": {
			sort: 0,
			name: 'Reviews Number',
			type: 'number',
			value: 128,
			min_num: 1,
			section: 'content'
		},

		// UI
		"padding_top": stdComponentDataList('slider', 1, 'Top Padding', 'Padding', 'pt', 24),
		"padding_bottom": stdComponentDataList('slider', 2, 'Bottom Padding', 'Padding', 'pb', 32),
	}
}
//<--dynamicData

export default function PageReviews({ data }: any)
{
	const [state, setState] = useState('google');

	const av_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review.source_id === 1;
	})

	const google_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review.source_id === 2;
	})

	let reviews;

	switch (state)
	{
		case 'av': {
			reviews = av_reviews;
			break;
		}
		case 'google': {
			reviews = google_reviews;
			break;
		}
		default: {
			reviews = google_reviews;
			break;
		}
	}

	return (
		<section
			id={dynamicData.uid}
			className="">

			<div className={`${dynamicData?.data?.padding_top?.value ?? 'pt-24'} ${dynamicData?.data?.padding_bottom?.value ?? 'pb-32'} 
    	px-4 sm:px-6 xl:px-0 mx-auto max-w-screen-xl`}>

				<div className="flex flex-wrap justify-between items-start">

					{/* title */}
					<h1 className="font-bold capitalize subpixel-antialiased">
						Reviews
					</h1>

					{/* Tab buttons */}
					<div className="flex pt-2 overflow-x-auto overflow-y-hidden border-b border-neutral-200">

						<button
							onClick={() => setState('google')}
							className={state === 'google'
								? 'inline-flex leading-tight items-center px-4 py-2 -mb-px text-left md:text-center text-primary-500 bg-transparent border-b-2 border-primary-500 focus:outline-none'
								: 'inline-flex leading-tight items-center px-4 py-2 -mb-px text-left md:text-center text-neutral-600 bg-transparent border-b-2 border-neutral-500 focus:outline-none'}>
							<h2 className="text-base capitalize subpixel-antialiased">
								Reviews from Google
							</h2>
						</button>

						<button
							onClick={() => setState('av')}
							className={state === 'av'
								? 'inline-flex leading-tight items-center px-4 py-2 -mb-px text-left md:text-center text-primary-500 bg-transparent border-b-2 border-primary-500 focus:outline-none'
								: 'inline-flex leading-tight items-center px-4 py-2 -mb-px text-left md:text-center text-neutral-600 bg-transparent border-b-2 border-neutral-500 focus:outline-none'}>
							<h2 className="text-base capitalize subpixel-antialiased">
								Certified by AutoVitals
							</h2>
						</button>

					</div>

				</div>

				<ul className="gap-x-8 gap-y-10 mt-8 gap-8 columns-1 sm:columns-2 lg:columns-3 break-inside-avoid-column">
					{
						reviews?.map((review: any, idx: any) =>
						{
							return (

								<li className="rounded overflow-hidden bg-neutral-50 w-full mx-auto mb-12 group sm:max-w-sm relative break-inside-avoid" key={idx}>
									<div className="p-6">

										<blockquote
											className="flex flex-wrap overflow-hidden mx-4 md:mx-0"
											key={idx}>

											<div className="flex flex-col w-full">
												<Rating rating={review?.rating ?? 5} color={""} />
												{
													state === 'av' &&
													<h3 className="text-base font-bold mb-2 capitalize subpixel-antialiased leading-snug">
														{review?.author_name ?? 'Unknown Author'}

														{review?.location
															? ' from ' + review?.location
															: ''}

														{review?.date
															? ' on ' + new Date(review.date).toLocaleDateString('en-US')
															: '—'}

														{review?.ymm
															? ' for an auto service of their ' + review?.ymm
															: 'car'
														}
													</h3>
												}
												{
													state === 'google' &&
													<h3 className="text-base font-bold mb-2 capitalize subpixel-antialiased leading-snug">
														{review?.author_name ?? 'Unknown Author'}

														{review?.date
															? ' on ' + new Date(review.date).toLocaleDateString('en-US')
															: '—'}
													</h3>
												}
											</div>

											<p className="w-full text-neutral-600">
												{review?.content ?? '—'}
											</p>

										</blockquote>
									</div>

								</li>

							)
						})
					}
				</ul>
			</div >

		</section>
	)
}