"use client";

declare global {
	interface Window {
		Wistia: any;
	}
}

import React, { useEffect, useRef } from 'react';

const WistiaEmbed = () => {
	const wistiaRef = useRef(null);

	useEffect(() => {
		// Dynamically load the Wistia script
		const script = document.createElement('script');
		script.src = "//fast.wistia.com/assets/external/playlist-v1.js";
		script.charset = "ISO-8859-1";
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			// Initialize the Wistia playlist once the script is loaded
			window.Wistia.playlist("1n6492l8d4", {
				version: "v1",
				theme: "bento",
				videoOptions: {
					volumeControl: true,
					autoPlay: true,
					videoWidth: "576",
					videoHeight: "324"
				},
				media_0_0: {
					autoPlay: false,
					controlsVisibleOnLoad: false
				},
				plugin: {
					"socialbar-v1": {
						position: "bottom",
						buttons: "twitter-googlePlus-facebook",
						logo: "true",
						badgeUrl: "http://www.autovitals.com/mediacenter",
						badgeImage: "http://embed.wistia.com/deliveries/d58d182a894d86aaa3689db801dae4ebcaeca63a.jpg?image_crop_resized=100x20"
					}
				},
				bento: {
					menuPosition: "bottom"
				}
			});
		};

		return () => {
			// Cleanup the script when the component unmounts
			document.body.removeChild(script);
		};
	}, []);

	return (
		<div>
			<div ref={wistiaRef} id="wistia_1n6492l8d4" className="wistia_embed rounded overflow-hidden w-full" style={{ width: '100%', height: '900px' }} data-video-width="576" data-video-height="324"></div>
		</div>
	);
};

export default WistiaEmbed;