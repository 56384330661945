// https://www.hyperui.dev/components/marketing/testimonials#component-1
"use client";

import Image from 'next/image'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

export default function Blog({ data }: any) {

  return (
    <section>

      <div className="py-24 lg:me-0 lg:pe-0">
        <div className="static grid grid-cols-1 lg:grid-cols-3 ">

          {/* Title */ }
          <div className="lg:flex lg:flex-col justify-between mx-auto px-4 sm:px-6 lg:px-8">

            <div className="">
              <h2 className="font-display font-bold text-2xl sm:text-4xl capitalize mb-8">
                Recent Posts
              </h2>
              <p className="text-neutral-600 pr-4">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas
                veritatis illo placeat harum porro optio fugit a culpa sunt id!
              </p>
            </div>

            {/* Buttons desktop */ }
            <div className="hidden lg:mt-8 lg:flex">
              <button className="prev-button border border-primary-600 p-3 text-primary-600 hover:bg-primary-600 hover:text-white">
                <span className="sr-only">Previous Slide</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5 rtl:rotate-180">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </button>

              <button className="next-button border border-l-0 border-primary-600 p-3 text-primary-600 hover:bg-primary-600 hover:text-white">
                <span className="sr-only">Next Slide</span>
                <svg
                  className="h-5 w-5 rtl:rotate-180"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Slider */ }
          <div className="mt-8 lg:col-span-2 lg:mx-0 lg:mt-20">

            <Swiper
              navigation={ {
                nextEl: '.next-button',
                prevEl: '.prev-button',
              } }
              modules={ [ Navigation ] }
              spaceBetween={ 0 }
              slidesPerView={ 1 }
              breakpoints={ {
                768: {
                  slidesPerView: 1.5,
                },
              } }
              loop={ true }
            >
              <div className="swiper-wrapper grid grid-cols-3">

                {/* First slide */ }
                <SwiperSlide>
                  <blockquote className="flex flex-col md:flex-row bg-secondary-100 overflow-hidden mx-4 md:mx-0">

                    <a href="#" className="relative flex content-stretch w-full md:w-1/3 overflow-hidden">
                      <Image
                        alt="Party"
                        src="https://wat.autovitals.com/webpresence_DSP_shop/img/office/office7.webp"
                        className="object-cover"
                        fill
                      />
                    </a>

                    <div className="flex flex-col w-full md:w-2/3 p-4 lg:py-8 lg:pl-4 lg:pr-16">
                      <span className="mb-10 text-sm text-secondary-500">July 19, 2023</span>

                      <h3 className="mb-2 text-xl font-bold  line-clamp-2">
                        <a href="#" className="transition duration-100 hover:text-indigo-500 active:text-indigo-600">
                          Keeping It Cool: Safety Tips for Traveling with Your Dog
                        </a>
                      </h3>

                      <p className="mb-10 text-neutral-600 line-clamp-5">
                        Traveling with your furry friend can be a fun and rewarding experience, but its important to take extra precautions to keep them safe and comfortable. As temperatures rise, its crucial to make sure your dog stays cool and hydrated. Here are some essential safety tips for traveling with your dog: Pre-Trip Preparation Before embarking on your journey, its essential to take some steps to prepare your dog for the trip.
                      </p>

                      <div>
                        <a href="#" className="text-primary-600 transition duration-100 hover:text-primary-600 active:text-primary-700">Read more</a>
                      </div>
                    </div>
                  </blockquote>
                </SwiperSlide>

                {/* Second slide */ }
                <SwiperSlide>
                  <blockquote className="flex flex-col md:flex-row bg-primary-200 overflow-hidden mx-4 md:mx-0">

                    <a href="#" className="relative flex content-stretch w-full md:w-1/3 overflow-hidden">
                      <Image
                        alt="Party"
                        src="https://wat.autovitals.com/webpresence_DSP_shop/img/office/office4.webp"
                        className="object-cover"
                        fill
                      />
                    </a>

                    <div className="flex flex-col w-full md:w-2/3 p-4 lg:p-8">
                      <span className="mb-10 text-sm text-primary-500">July 29, 2023</span>

                      <h3 className="mb-2 text-xl font-bold  line-clamp-2">
                        <a href="#" className="transition duration-100 hover:text-indigo-500 active:text-indigo-600">
                          Let`s Get Your Vehicle Ready for School Season
                        </a>
                      </h3>

                      <p className="mb-10 text-neutral-600 line-clamp-5">
                        Let`s Get Your Vehicle Ready for School Season (9 Tips From The Pros!) As the school season approaches, ensuring that your vehicle is in optimal condition is essential to keep your family safe and prepared for the daily commute. Taking a proactive approach to vehicle maintenance can help prevent unexpected breakdowns and ensure smooth travels.
                      </p>

                      <div>
                        <a href="#" className="text-primary-600 transition duration-100 hover:text-primary-600 active:text-primary-700">Read more</a>
                      </div>
                    </div>
                  </blockquote>
                </SwiperSlide>

                {/* Third slide */ }
                <SwiperSlide>
                  <blockquote className="flex flex-col md:flex-row bg-secondary-100 overflow-hidden mx-4 md:mx-0">

                    <a href="#" className="relative flex content-stretch w-full md:w-1/3 overflow-hidden">
                      <Image
                        alt="Party"
                        src="https://wat.autovitals.com/webpresence_DSP_shop/img/office/office5.webp"
                        className="object-cover"
                        fill
                      />
                    </a>

                    <div className="flex flex-col w-full md:w-2/3 p-4 lg:p-8">
                      <span className="mb-10 text-sm text-secondary-500">August 8, 2023</span>

                      <h3 className="mb-2 text-xl font-bold  line-clamp-2">
                        <a href="#" className="transition duration-100 hover:text-indigo-500 active:text-indigo-600">
                          Car Maintenance: Everything You Need to Know to Extend the Lifespan of Your Vehicle
                        </a>
                      </h3>

                      <p className="mb-10 text-neutral-600 line-clamp-5">
                        Image via Pexels This article was written by guest writer, Ashley McLean. Owning a car can bring about a sense of independence and convenience, but it also comes with its fair share of responsibilities. On top of paying for gas and insurance, vehicle owners must also ensure the car remains in good condition to guarantee longevity and smooth operation. With proper car maintenance, you can significantly extend your vehicle`s lifespan while also minimizing costly repairs.
                      </p>

                      <div>
                        <a href="#" className="text-primary-600 transition duration-100 hover:text-primary-600 active:text-primary-700">Read more</a>
                      </div>
                    </div>
                  </blockquote>
                </SwiperSlide>
              </div>

            </Swiper>

          </div>
        </div>

        {/* Buttons mobile */ }
        <div className="px-4 sm:px-6 mt-8 flex gap-4 lg:hidden">

          <button
            // onClick={() => swiper.slidePrev()}
            aria-label="Previous slide"
            className="prev-button border border-primary-600 p-4 text-primary-600 hover:bg-primary-600 hover:text-white">
            <svg
              className="h-5 w-5 -rotate-180 transform"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5l7 7-7 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>

          <button
            // onClick={() => swiper.slideNext()}
            aria-label="Next slide"
            className="next-button border border-primary-600 p-4 text-primary-600 hover:bg-primary-600 hover:text-white">
            <svg
              className="h-5 w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5l7 7-7 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>

        </div>
      </div>

    </section>
  )
}
