"use client";

import React, { useRef, useState } from 'react';

const FaqsCard = (props: any) => {

  const answerElRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState(false)
  const [answerH, setAnswerH] = useState('0px')
  const { faqsList, idx } = props

  const handleOpenAnswer = () => {
    const answerElH = (answerElRef?.current?.children[0] as HTMLDivElement).offsetHeight;
    setState(!state)
    setAnswerH(`${answerElH + 20}px`)
  }

  return (
    <div
      className="space-y-3 mt-5 overflow-hidden border-b"
      key={ idx }
      onClick={ handleOpenAnswer }
    >
      <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-neutral-700 font-medium">

        { faqsList.faqs.question }
        {
          state ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-neutral-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-neutral-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={ 2 } d="M12 4v16m8-8H4" />
            </svg>
          )
        }
      </h4>
      <div
        ref={ answerElRef } className="duration-300"
        style={ state ? { height: answerH } : { height: '0px' } }
      >
        <div>
          <p className="text-neutral-500">
            { faqsList.faqs.answer }
          </p>
        </div>
      </div>
    </div>
  )
}

export default function Faq({ data }: any) {
  return (
    <section className="leading-relaxed mt-12 md:w-2/3 mx-auto">
      <div className="bg-neutral-100 mx-4 px-8 py-20 rounded-lg">
        <div className="space-y-3 max-w-2xl mx-auto">
          <h2 className="font-display text-3xl text-neutral-800 font-semibold">
            FAQ
          </h2>
          <p className="text-neutral-600 max-w-2xl mx-auto text-lg">
            Answered all frequently asked questions, Still confused? feel free to contact us.
          </p>
        </div>
        <div className="mt-14 max-w-2xl mx-auto">
          {
            data?.faqs.map((item: any, idx: any) => (
              <FaqsCard
                key={ 'faq' + idx }
                idx={ idx }
                faqsList={ item }
              />
            ))
          }
        </div>
      </div>
    </section>
  )
}