import { StdOptionListItem } from '@/utils/types';

export const stdComponentDataList = (type: string, sort: Number, name: string, caption: string, cssTag: string, default_val: any, section?: string, set?: string) =>
{

	let opts: StdOptionListItem[] = [];
	let lst: Number[] = [];

	if (set == 'tints')
	{
		lst = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];
	} else
	{
		lst = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 72, 80, 96];
	}

	if (cssTag && cssTag.length > 0)
	{
		opts = lst.map((item) =>
		{
			return {
				display: `${caption} ${item}`,
				value: `${cssTag} ${item}`,
			}
		});
	} else
	{
		opts = lst.map((item) =>
		{
			return {
				display: `${caption} ${item}`,
				value: `${item}`,
			}
		});
	}

	if (typeof default_val != 'string' || (typeof default_val == 'string' && !default_val.startsWith(cssTag)))
	{
		if (cssTag && cssTag.length > 0)
		{
			default_val = `${cssTag}-${default_val}`;
		} else
		{
			default_val = `${default_val}`;

		}
	}

	return {
		sort: sort,
		name: name,
		type: type,
		value: default_val,
		options: opts,
		section: section ?? 'ui',
		unit: cssTag
	}
}

export const StdTints: StdOptionListItem[] = [
	{
		display: "50",
		value: '50'
	},
	{
		display: "100",
		value: '100'
	},
	{
		display: "200",
		value: '200'
	},
	{
		display: "300",
		value: '300'
	},
	{
		display: "400",
		value: '400'
	},
	{
		display: "500",
		value: '500'
	},
	{
		display: "600",
		value: '600'
	},
	{
		display: "700",
		value: '700'
	},
	{
		display: "800",
		value: '800'
	},
	{
		display: "900",
		value: '900'
	},
	{
		display: "950",
		value: '950'
	},
]