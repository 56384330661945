"use client";

import Link from 'next/link'
import Image from 'next/image'
import { useEffect, useState } from 'react';
import FAIcon from "@/base-components/icons";

export default function PageServicesList({ data }: any)
{

	const [filteredList, setFilteredList] = useState(data);
	const [query, setSearchQuery] = useState("");

	const searchStr = (searchString: string) =>
	{

		for (const cat of data['service_categories'])
		{
			let catCount = 0;
			if (cat['services'] && cat['services'].length)
			{
				for (const service of cat['services'])
				{
					if (service['name'] || (!service['name'] && service['service']['name']))
					{
						if (searchString == "" || ((service['name'] && service['name'].toLowerCase().includes(searchString)) || (!service['name'] && service['service']['name'].toLowerCase().includes(searchString))))
						{
							service['show'] = true;
							catCount++;
						} else
						{
							service['show'] = false;
						}
					}
				}
			}
			if (catCount)
			{
				cat['show'] = true;
			} else
			{
				cat['show'] = false;
			}
		}
	}

	const filterBySearch = (event: any) =>
	{
		const query = event.target.value;

		searchStr(query.toLowerCase())

		setSearchQuery(query);
		setFilteredList(data);
	}

	const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

	const toBase64 = (str: string) =>
		typeof window === 'undefined'
			? Buffer.from(str).toString('base64')
			: window.btoa(str)


	const getHighlightedText = (text: string, highlight?: string) =>
	{
		// Split on highlight term and include term into parts, ignore case
		if (!text)
		{
			return '';
		}
		const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
		return <span> {parts.map((part, i) =>
			<span key={i} className={part.toLowerCase() === highlight?.toLowerCase() ? 'underline decoration-dotted decoration-green-800 decoration-2 bg-green-800/25' : ''}>
				{part}
			</span>)
		} </span>;
	}

	return (

		<section className="py-28">
			<div className="max-w-screen-xl mx-auto px-4 md:px-8">

				<div className="space-y-5 sm:text-center max-w-none md:max-w-2xl sm:mx-auto">
					<h1 className="text-3xl font-bold sm:text-4xl capitalize">
						Services
					</h1>
					<div className="pt-2 relative mx-auto text-neutral-600">
						<input onChange={filterBySearch} className="border border-neutral-300 w-full h-10 pl-10 px-4 rounded-lg text-sm focus:outline-none"
							type="search" name="search" placeholder="Search" />
						<button type="submit" className="absolute left-0 top-0 mt-4 ml-4">
							<FAIcon type="solid" nameIco='fa-magnifying-glass' customClass="h-4" />
						</button>
					</div>
				</div>

				<ul className="gap-x-8 gap-y-10 mt-16 gap-8 columns-1 sm:columns-2 lg:columns-3 break-inside-avoid-column">
					{
						(filteredList && filteredList['service_categories'] && filteredList['service_categories'].length) ? filteredList['service_categories'].map((servicesCategory: any, key: any) =>
						{
							if (servicesCategory?.enabled && (typeof servicesCategory?.show === 'undefined' || servicesCategory?.show === true))
							{
								return (

									<li className="rounded-lg overflow-hidden bg-neutral-100 w-full mx-auto mb-12 group sm:max-w-sm relative break-inside-avoid" key={key}>

										<div className="overflow-hidden w-full aspect-video relative rounded-lg">
											<Image
												blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
												src={`https://wat.autovitals.com/webpresence_DSP_shop/img/service-${servicesCategory['CategoryId']}.jpg`}
												fill
												style={{ objectFit: "cover" }}
												alt={servicesCategory.Name}
												className="bottom-0 w-full hover:scale-110 transition duration-200"
											/>
										</div>

										<div className="p-6">

											<h3 className="mb-2 text-lg duration-150 font-bold">
												{servicesCategory?.name ?? servicesCategory?.service_category?.name}
											</h3>

											<ul className="">
												{
													(servicesCategory['services'] && servicesCategory['services'].length) ? servicesCategory['services'].map((service: any, key: any) =>
													{
														if (typeof service['show'] === 'undefined' || service['show'] === true)
														{
															if (service['slug'] == '')
															{
																return (
																	<li className="mt-1" key={key}>
																		<span>
																			{service?.name ?? service?.service?.name}
																		</span>
																	</li>
																)
															} else
															{
																return (
																	<li className="mt-1" key={key}>
																		<Link href={`/services/${service.slug}`} className="text-neutral-600 text-sm duration-150 hover:text-blue-600">
																			{getHighlightedText(service?.name ?? service?.service?.name, query)}
																		</Link>
																	</li>
																)
															}
														}
													}) : ''
												}
											</ul>
										</div>

									</li>

								)
							}
						}) : ''
					}
				</ul>
			</div>
		</section>

	)
}