"use client";

import Link from 'next/link'
import Image from 'next/image'
import { useEffect, useState } from 'react';

export default function ServicesList({ data }: any) {

	const [filteredList, setFilteredList] = useState(data);
	const [query, setSearchQuery] = useState("");

	const searchStr = (searchString: string) => {

		for (const cat of data) {
			let catCount = 0;
			if (cat['services'] && cat['services'].length) {
				for (const service of cat['services']) {
					if (service['Name']) {
						if (searchString == "" || service['Name'].toLowerCase().includes(searchString)) {
							service['Show'] = true;
							catCount++;
						} else {
							service['Show'] = false;
						}
					}
				}
			}
			if (catCount) {
				cat['Show'] = true;
			} else {
				cat['Show'] = false;
			}
		}
	}

	const filterBySearch = (event: any) => {
		const query = event.target.value;

		searchStr(query.toLowerCase())

		setSearchQuery(query);
		setFilteredList(data);
	}

	const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

	const toBase64 = (str: string) =>
		typeof window === 'undefined'
			? Buffer.from(str).toString('base64')
			: window.btoa(str)


	const getHighlightedText = (text: string, highlight?: string) => {
		// Split on highlight term and include term into parts, ignore case
		const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
		return <span> {parts.map((part, i) =>
			<span key={i} className={part.toLowerCase() === highlight?.toLowerCase() ? 'underline decoration-dotted decoration-green-800 decoration-2 bg-green-800/25' : ''}>
				{part}
			</span>)
		} </span>;
	}


	return (

		<section className="py-28">
			<div className="max-w-screen-xl mx-auto px-4 md:px-8">

				<div className="space-y-5 sm:text-center max-w-none md:max-w-2xl sm:mx-auto">
					<h1 className="text-gray-800 text-3xl font-bold sm:text-4xl">Services</h1>
					<div className="pt-2 relative mx-auto text-gray-600">
						<input onChange={filterBySearch} className="border-2 border-gray-300 bg-white w-full h-10 pl-10 px-4 rounded-lg text-sm focus:outline-none"
							type="search" name="search" placeholder="Search" />
						<button type="submit" className="absolute left-0 top-0 mt-5 ml-5">
							<svg className="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px"
								viewBox="0 0 56.966 56.966"
								width="512px" height="512px">
								<path
									d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
							</svg>
						</button>
					</div>
				</div>

				<ul className="gap-x-8 gap-y-10 mt-16 gap-8 columns-1 sm:columns-2 lg:columns-3 break-inside-avoid-column">
					{
						filteredList.map((servicesCategory: any, key: any) => {
							if (servicesCategory['Enabled'] && (servicesCategory['Show'] === true || typeof servicesCategory['Show'] === 'undefined')) {
								return (
									<li className="rounded-lg overflow-hidden bg-gray-50 w-full mx-auto mb-12 group sm:max-w-sm relative break-inside-avoid" key={key}>
										<div className="overflow-hidden w-full h-32 relative rounded-lg">
											<Image blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`} src={`https://wat.autovitals.com/webpresence_DSP_shop/img/service-${servicesCategory['CategoryId']}.jpg`} fill style={{ objectFit: "cover" }} alt="" className="bottom-0 w-full hover:scale-110 transition duration-200" />
										</div>

										<div className="p-6">
											<h3 className="text-lg text-gray-800 duration-150 font-semibold">
												{servicesCategory.Name}
											</h3>
											<ul className="mt-1">
												{
													servicesCategory['services'].map((service: any, key: any) => {
														if (service['Show'] === true || typeof service['Show'] === 'undefined') {
															if (service['Slug'] == '') {
																return (
																	<li className="mt-1" key={key}>
																		<span>
																			{service.Name}
																		</span>
																	</li>
																)
															} else {
																return (
																	<li className="mt-1" key={key}>
																		<Link href={`/services/${service.Slug}`} className="text-gray-600 text-sm duration-150 hover:text-blue-600">
																			{getHighlightedText(service.Name, query)}
																		</Link>
																	</li>
																)
															}
														}
													})
												}
											</ul>
										</div>
									</li>
								)
							}
						})
					}
				</ul>
			</div>
		</section>

	)
}