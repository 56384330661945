'use client';

import GoogleMap from 'google-maps-react-markers'
import { useRef, useState } from 'react'

const Map = ({ lat, lng, zoom }: { lat: number, lng: number, zoom?: number }) =>
{
  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState(false)

  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */
  const onGoogleApiLoaded = ({ map, maps }: any) =>
  {
    mapRef.current = map
    setMapReady(true)
  }
  return (
    <>
      <GoogleMap
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
        defaultCenter={{ lat: lat, lng: lng }}
        defaultZoom={zoom ?? 16}
        onGoogleApiLoaded={onGoogleApiLoaded}
      >
      </GoogleMap >
    </>
  )
}

export default Map