"use client";

import Link from 'next/link';
import Image from "next/image";
import { useEffect, useState } from 'react';
import { NavItem } from "@/utils/types";

//-->dynamicData
let dynamicData = {
	uid: "nav-bar-logo-menu-cta",
	data: {
		'style_slim': {
			sort: 1,
			name: 'Slim navbar',
			type: 'checkbox',
			value: false,
			section: 'ui'
		},
		'style_bg_color': {
			sort: 2,
			name: 'Dark theme',
			type: 'color',
			value: "",
			section: 'ui'
		}
	}
};
//<--dynamicData

const dropdownNavs: any = {
	"About": [
		{
			title: "About Us",
			path: "/about"
		},
		{
			title: "Reviews",
			path: "/reviews"
		},
		{
			title: "Our Blog",
			path: "/blog"
		},
		{
			title: "Specials",
			path: "/specials"
		},
		{
			title: "Our Team",
			path: "/team"
		},
		{
			title: "FAQ",
			path: "/faq"
		},
		{
			title: "Contact",
			path: "/contact"
		},
	],
	"Landing Pages": [],
	"Locations": [],
	"We Are The Digital Shop": [
		{
			title: "What is SmartFlow?",
			path: "/"
		},
		{
			title: "Digital Inspections",
			path: "/"
		},
	]
}

export default function NavBar({ data, config }: any)
{

	if (config && config.uid)
	{
		dynamicData = config;
	}

	const name: string | null | undefined = data?.info?.name;
	const city: string | null | undefined = data?.info?.city;
	const address: string | null | undefined = data?.info?.address;
	const phone: string | null | undefined = data?.info?.phone_number;
	const st: string | null | undefined = data?.info?.state;

	const [state, setState] = useState(false)
	const [DropdownState, setDropdownState] = useState<any>({ isActive: false, idx: null })

	dropdownNavs["Landing Pages"] = data.landings?.map((x: NavItem) =>
	{
		x.title = x.name;
		x.path = `/landing/${x.slug}`;
		return x
	}) ?? [];
	dropdownNavs["Locations"] = data.locations;

	const navigation = [
		{ title: "Home", path: "/", isDropdown: false },
		{ title: "Services", path: "services", isDropdown: false },
		{ title: "Service Videos", path: "service-videos", isDropdown: false },
		{ title: "About", path: "about", isDropdown: true, dropNavs: dropdownNavs },
		{ title: "Contact", path: "/contact", isDropdown: false }
	]

	useEffect(() =>
	{
		document.onclick = (e) =>
		{
			const target = e.target as HTMLElement;
			if (!target.closest(".nav-menu")) setDropdownState({ isActive: false, idx: null });
		};
	}, [])

	return (
		<>
			<nav className={`relative z-40 ${dynamicData.data.style_bg_color.value ? dynamicData.data.style_bg_color.value : "bg-white"} w-full md:static md:text-sm md:border-none ${state ? "shadow-lg rounded-b-xl md:shadow-none" : ""}`}>
				<div className="justify-between items-center px-4 md:px-8 max-w-screen-xl mx-auto md:flex">
					<div className={`flex items-center justify-between ${dynamicData.data.style_slim.value ? "py-1 md:py-2" : "py-3 md:py-5"} md:block`}>

						{/* Logo */}
						<Link href="/" title="DSX Next Prototype" aria-label="DSX Next Prototype">
							<Image
								width={dynamicData.data.style_slim.value ? "144" : "214"}
								height={dynamicData.data.style_slim.value ? "32" : "48"}
								src={"/images/autovitals.svg"}
								alt={"Logotype"}
							/>
						</Link>

						{/* Mobile burger */}
						<div className="md:hidden">
							<button className="text-neutral-500 hover:text-neutral-800" aria-label="Main navigation" title="Main navigation"
								onClick={() => setState(!state)}
							>
								{
									state ? (
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
											<path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
										</svg>
									) : (
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
											<path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
										</svg>
									)
								}
							</button>
						</div>
					</div>

					<div className={`nav-menu pb-4 mt-8 md:block md:pb-0 md:mt-0 ${state ? 'block' : 'hidden'}`}>
						<ul className="items-center space-y-6 md:flex md:space-x-6 md:space-y-0">

							{/* Menu */}
							{
								navigation.map((item, idx) =>
								{
									return (
										<li key={idx}>
											{
												item.isDropdown ? (
													<button
														className="w-full flex items-center justify-between gap-1 text-neutral-600 hover:text-primary-600"
														onClick={() => setDropdownState({ isActive: !DropdownState.isActive, idx: idx })}
													>
														{item.title}
														{
															DropdownState.idx == idx && DropdownState.isActive ? (
																<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
																	<path fillRule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clipRule="evenodd" />
																</svg>

															) : (
																<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
																	<path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
																</svg>
															)
														}
													</button>
												) : (
													<a href={item.path} className="block text-neutral-600 hover:text-primary-600">
														{item.title}
													</a>
												)
											}
											{
												item.isDropdown && DropdownState.idx == idx && DropdownState.isActive ? (
													<div className="z-40 mt-6 inset-x-0 bg-white w-full md:absolute md:border-y md:shadow-md">
														<ul className='max-w-screen-xl mx-auto grid items-top gap-6 md:p-8 md:grid-cols-2 lg:grid-cols-4'>
															{
																Object.keys(item.dropNavs).map((k: string, index: number) =>
																{
																	let nItem = item.dropNavs[k];
																	return (
																		<li key={k}>
																			<p className="text-lg"><b>{k}</b></p>
																			<ul className='mt-5 space-y-3'>
																				{nItem.map((navItem: any, idx: number) => (
																					<li key={idx}>
																						<a href={navItem.path} className='flex gap-3 items-center'>{navItem.title}</a>
																					</li>
																				))}
																			</ul>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : ""
											}
										</li>
									)
								})
							}

							<hr className="max-w-screen-xl mx-auto border-neutral-300 dark:border-neutral-700" />

							{/* Mobile contacts */}
							<div className='flex flex-wrap sm:flex-nowrap text-neutral-500 text-xs justify-between space-y-3 sm:space-y-0 md:hidden md:space-y-0'>
								<a href="#" rel="noopener noreferrer" className="md:items-center items-top gap-2 flex w-full sm:w-1/4">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-neutral-400">
										<path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
									</svg>
									<span className="hover:underline focus-visible:underline">{phone}</span>
								</a>
								<a href="#" rel="noopener noreferrer" className="md:items-center items-top gap-2 flex w-full sm:w-1/3">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-neutral-400">
										<path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
										<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
									</svg>
									<span className="hover:underline focus-visible:underline">{address + ', ' + city + ', ' + st}</span>
								</a>
								<a href="#" rel="noopener noreferrer" className="md:items-center items-top gap-2 flex w-full sm:w-max">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-neutral-400">
										<path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
									</svg>
									<span className="hover:underline focus-visible:underline">Sun–Mon: 12.00–18.00, Tue–Fri: 9.00–19.00, Sat: 10.00–18.00</span>
								</a>
							</div>

						</ul>
					</div>

					{/* Button */}
					<div className={`items-center ${dynamicData.data.style_slim.value ? "py-2" : "py-8"} md:space-y-0 md:block ${state ? 'block' : 'hidden'}`}>
						<a href="#" className={`block ${dynamicData.data.style_slim.value ? "py-2" : "py-3"} px-4 font-medium text-center text-primary-500 border-primary-500 hover:text-primary-700 border hover:border-primary-700 active:bg-primary-700 rounded-lg focus:outline-none focus:ring`}>
							Call Us
						</a>
					</div>

				</div>
			</nav>
			{
				state ? (
					<div
						className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm md:hidden"
						onClick={() => setState(false)}></div>
				) : ""
			}
		</>
	)
}