'use client';

import FAIcon from "@/base-components/icons";
import { DsxImageClient } from "@/base-components/image";
import { special } from "@/types/graphql";
import { DsxImageType } from "@/utils/types";
import Link from "next/link";

function printCard(id: string)
{
  if (document)
  {
    let printContents = document.getElementById(id)?.innerHTML;
    let originalContents = document.body.innerHTML;

    printContents &&
      (document.body.innerHTML = printContents)

    window.print();

    document.body.innerHTML = originalContents;
  }
}

export default function CardSpecial({ key, special, img }: { key: number, special: special, img: DsxImageType })
{

  return (
    <>
      <li
        id={'special_card-' + key}
        key={key}
        className="group flex flex-col overflow-hidden rounded bg-neutral-50">

        {/* Special image */}
        <div className="relative block h-48 overflow-hidden md:h-64">
          <DsxImageClient dsxImage={img} />
        </div>

        {/* Special content */}
        <div className="flex flex-1 flex-col p-4 sm:p-6">

          {/* Special date */}
          <small className="mb-2 text-neutral-600 text-right">
            {special.end_date
              ? 'Until ' + new Date(special.end_date).toLocaleDateString('en-US')
              : 'Permanent'}
          </small>

          {/* Special title */}
          <h2 className="mb-2 text-lg font-bold subpixel-antialiased">
            {special.title}
          </h2>

          {/* Special text */}
          <div className="mb-8 line-clamp-8">
            {<div dangerouslySetInnerHTML={{ __html: special.description ?? '' }} />}
          </div>

          {/* Special primary button */}
          <div className="mt-auto mb-2 flex w-full">
            <Link
              href="#make-appointment"
              className="inline-flex text-center justify-center items-center w-full rounded bg-primary-500 px-8 py-3 font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring active:bg-primary-500 cursor-pointer">
              Request Appointment
              <FAIcon type="solid" nameIco='fa-arrow-right' customClass="h-4 text-white hover:text-primary-800 ml-2" />
            </Link>
          </div>

          {/* Special print button */}
          <div className="mb-4 flex w-full text-primary-500 hover:text-primary-700">
            <button
              onClick={() => printCard('special_card-' + key)}
              className="inline-flex text-center justify-center items-center w-full rounded px-8 py-3 font-medium focus:outline-none focus:ring active:border-primary-500 cursor-pointer">
              Print Coupon
              <FAIcon type="solid" nameIco='fa-print' customClass="h-4 ml-2" />
            </button>
          </div>

          {/* Special fineprint */}
          <small className="w-full text-neutral-600">
            {special.fine_print}
          </small>

        </div>

      </li>
    </>
  )

}