"use client"

import Image from "next/image";

export default function Services({ data }: any) {

	const name: string | null | undefined = data?.info?.name;

	const services = [
		{
			title: "A/C Compressor Repair",
			image: "https://wat.autovitals.com/webpresence_staging/webpresence_andreystestshop/img/service-ACCompressorRepair.jpg"
		},
		{
			title: "Brake Calipers",
			image: "https://wat.autovitals.com/webpresence_staging/webpresence_andreystestshop/img/service-BrakeCalipers.jpg"
		},
		{
			title: "Brake Fluid Service",
			image: "https://wat.autovitals.com/webpresence_staging/webpresence_andreystestshop/img/service-BrakeFluidService.jpg"
		},
		{
			title: "A/C Recharge Service",
			image: "https://wat.autovitals.com/webpresence_staging/webpresence_andreystestshop/img/service-ACRechargeService.jpg"
		},
		{
			title: "Alternator Repair",
			image: "https://wat.autovitals.com/webpresence_staging/webpresence_andreystestshop/img/service-AlternatorRepair.jpg"
		},
		{
			title: "Auto Glass",
			image: "https://wat.autovitals.com/webpresence_staging/webpresence_andreystestshop/img/service-AutoGlass.jpg"
		}
	]

	return (

		<section className="bg-white py-6 sm:py-8 lg:py-28 bg-gradient-to-br from-primary-700 to-[#67e8f9]">

			<div className="mx-auto max-w-screen-2xl px-4 md:px-8">

				{/* Top block */ }
				<h2 className="font-display text-primary-50 text-3xl font-bold sm:text-4xl mb-6 capitalize">
					Key Services from { name }
				</h2>
				<div className="flex items-start justify-between gap-8 sm:items-center mb-12">

					<p className="max-w-screen-sm text-sm text-primary-200 lg:text-base">
						This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text.
					</p>

					<a href="/services" className="inline-flex items-center text-center rounded px-12 py-3 text-sm font-medium text-primary-700 bg-white focus:outline-none focus:ring focus:ring-primary-200 hover:text-primary-500 outline-none transition duration-100 focus-visible:ring  md:px-8 md:py-3 md:text-base">
						Full List of Services
					</a>
				</div>

				{/* Srevices */ }
				<ul className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:mb-8 md:grid-cols-4 md:gap-6 xl:gap-8 justify-end items-end place-content-end">
					{
						services.map((service, idx) => (
							<a key={ idx } href={ `./services/` + service.title } className="text-center group relative flex h-48 items-end overflow-hidden rounded-lg bg-neutral-100 md:h-80">
								<li key={ idx } className="w-full max-w-xs mx-auto my-auto">

									{/* Service Title */ }
									<h3 className="relative text-lg font-bold text-primary-50 z-10">
										{ service.title }
									</h3>

									{/* Service Image */ }
									<Image
										className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
										fill
										src={ service.image }
										priority={ false }
										alt={ service.title }
									/>

									{/* Overlay */ }
									<div className="pointer-events-none absolute inset-0 bg-primary-900/70"></div>

								</li>
							</a>
						))
					}

				</ul>

			</div>
		</section>
	)
}
