import FAIcon from "@/base-components/icons";
import { getTextColor } from "@/utils/color";

export default function Rating({ rating, color }: { rating: number, color: string })
{
	switch (rating)
	{
		case 1:
			return <div className="ml-auto mb-2">
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
			</div>;

		case 2:
			return <div className="ml-auto mb-2" >
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
			</div>

		case 3:
			return <div className="ml-auto mb-2" >
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
			</div>

		case 4:
			return <div className="ml-auto mb-2" >
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
			</div>

		case 5:
			return <div className="ml-auto mb-2" >
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
			</div>

		default:
			<div className="ml-auto mb-2" >
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
				<FAIcon type="solid" nameIco='fa-star' customClass={`${color ? getTextColor(color) : 'text-[#FEAF00]'} h-4`} />
			</div>
	}
}
