export default function RadioButton({ id, value, name, required }: { id: string, value: string, name: string, required?: boolean })
{
  return (
    <>
      <input
        id={id}
        type="radio"
        value={value}
        name={name}
        required={required}
        className="w-4 h-4 appearance-none rounded-full checked:bg-primary-600 border-4 border-white ring-2 ring-primary-600 shrink-0"
      />
    </>
  )
}