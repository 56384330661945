import Image from 'next/image'
import { getDsxImage } from "@/utils/data";
import FAIcon from '@/base-components/icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { DsxImageType } from '@/utils/types';
import { log } from 'console';

export async function DsxImage(dsxImage: DsxImageType)
{

	const di: DsxImageType = await getDsxImage(dsxImage)

	if (di?.type == 2 && di.useIcon)
	{
		return (<FAIcon type={di.iconType} nameIco={di.iconName} customClass={di.class} />)
	} else
	{
		return (
			<Image
				fill={di.fill ?? true}
				alt={di.alt ?? ""}
				src={di.src ?? ""}
				className={di.className ?? ""}
				style={di.style ?? {}}
				priority={di.priority ?? false}
				sizes={di.sizes ?? ""}
			/>
		)
	}

}

export function DsxImageClient({ dsxImage }: { dsxImage: DsxImageType })
{

	if (dsxImage?.type == 2 && dsxImage.useIcon)
	{
		return (<FAIcon type={dsxImage.iconType} nameIco={dsxImage.iconName} customClass={dsxImage.class} />)
	} else
	{
		return (
			<Image
				fill={dsxImage.fill ?? true}
				alt={dsxImage.alt ?? ""}
				src={dsxImage.src ?? ""}
				className={dsxImage.className ?? ""}
				style={dsxImage.style ?? {}}
				priority={dsxImage.priority ?? false}
				sizes={dsxImage.sizes ?? ""}
			/>
		)
	}

}