// https://www.hyperui.dev/components/marketing/testimonials#component-2
"use client";

import FAIcon from "@/base-components/icons";
import { review } from "@/types/graphql";

export default function Reviews({ data }: any)
{

  // Array of selected reviews
  let selected_reviews = data?.reviews?.filter((review: review) =>
  {
    return review.selected;
  });

  return (

    <section className="px-4 sm:px-6 md:px-8 py-24">

      <div className="mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-12 items-end justify-between">

          {/* Title */}
          <h2 className={` col-start-2 text-2xl font-bold capitalize sm:text-4xl`}>
            Read trusted reviews from our customers
          </h2>

        </div>

        <div className="grid gap-y-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-12">
          {
            selected_reviews?.map((review: any, idx: number) => (

              <blockquote
                key={idx}
                className="flex h-full flex-col justify-between sm:pr-16">

                <div className="mb-2 md:mb-6">

                  <div className="flex gap-0.5 text-green-500">
                    <FAIcon type="solid" nameIco='fa-star' customClass="h-4 ml-1" />
                    <FAIcon type="solid" nameIco='fa-star' customClass="h-4 ml-1" />
                    <FAIcon type="solid" nameIco='fa-star' customClass="h-4 ml-1" />
                    <FAIcon type="solid" nameIco='fa-star' customClass="h-4 ml-1" />
                    <FAIcon type="solid" nameIco='fa-star' customClass="h-4 ml-1" />
                  </div>

                  <div className="mt-4">
                    <p className="mb-2 text-neutral-600 line-clamp-5">
                      {review?.content
                        ? review?.content
                        : '—'}
                    </p>
                  </div>

                </div>

                <footer className="text-neutral-400">
                  {review.author_name
                    ? "— " + review.author_name
                    : "— Unknown Author"}
                </footer>

              </blockquote>

            ))
          }
        </div>

        {/* CTA button */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <div className="flex col-start-2">
            <a href="#" className="inline-flex bg-primary-500 px-8 py-3 font-medium text-white transition hover:bg-blue-700 focus:outline-none focus:ring focus:ring-yellow-400" >
              Read all reviews
              <FAIcon type="solid" nameIco='fa-arrow-right' customClass="h-4 text-white ml-2" />
            </a>
          </div>
        </div>

      </div>

    </section>

  )
}
