'use client';

import FAIcon from "@/base-components/icons";
import { shop } from "@/types/graphql";
import { ColorProperty, getBgColor, getColor, getTextColor } from "@/utils/color";
import { getWorkHoursShort } from "@/utils/date";
import { ShopColors } from "@/utils/types";
import Link from "next/link";
import { useEffect, useState } from 'react';

export default function Navigation({ dynamicData, navItems, data }: { dynamicData: any, navItems: any, data: shop })
{

  const [state, setState] = useState(false);
  const [DropdownState, setDropdownState] = useState<any>({ isActive: false, idx: null });

  const name: string | null | undefined = data?.info?.name;
  const city: string | null | undefined = data?.info?.city;
  const address: string | null | undefined = data?.info?.address;
  const phone: string | null | undefined = data?.info?.phone_number;
  const st: string | null | undefined = data?.info?.state;

  // Close the menu on click outside it
  useEffect(() =>
  {
    document.onclick = (e) =>
    {
      const target = e.target as HTMLElement;
      if (!target.closest(".nav-menu") || (target.closest(".nav-menu") && target.tagName == 'A')) setDropdownState({ isActive: false, idx: null });
    };
  }, [])

  const nav_bg_color: ShopColors = {
    primary: 'bg-primary-500 lg:bg-primary-600',
    secondary: 'bg-secondary-500 lg:bg-secondary-600',
    neutral: 'bg-neutral-500 lg:bg-neutral-600',
    unset: 'bg-neutral-50 lg:bg-neutral-100'
  }

  const nav_text_color: ShopColors = {
    primary: 'text-primary-500 hover:text-primary-600',
    secondary: 'text-secondary-500 hover:text-secondary-600',
    neutral: 'text-neutral-700 hover:text-neutral-600',
    unset: 'text-white hover:text-primary-200'
  }

  return (
    <>

      {/* {
        state ? (
          <div
            className="z-0 fixed left-0 top-0 w-screen h-screen bg-black/20 backdrop-blur-sm"
            onClick={() => setState(false)
            }></div >
        ) : ""
      } */}

      <div className={`${state
        ? 'w-full'
        : ''} `}>

        {/* Mobile burger */}
        <div className="py-4 lg:hidden">
          <button
            className={`${nav_text_color[dynamicData.data.nav_text_color.value as keyof ShopColors]} flex ml-auto`}
            aria-label="Main navigation"
            title="Main navigation"
            onClick={() => setState(!state)}>
            {
              state ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                </svg>
              )
            }
          </button>
        </div>

        <div className={`${state
          ? 'block'
          : 'hidden'} nav-menu pb-4 mt-8 lg:block md:pb-0 lg:mt-0`}>

          <div className="space-y-6">

            {/* Menu */}
            <ul className="items-center space-y-6 lg:flex lg:space-x-8 lg:space-y-0">
              {
                navItems.map((item: any, idx: number) =>
                {
                  return (
                    <li key={idx}>
                      {
                        item.isDropdown
                          ?
                          (
                            <div className="flex items-center justify-between gap-2">

                              <Link
                                id={''}
                                href={item.path}
                                className={`
                                ${nav_text_color[dynamicData?.data?.nav_text_color?.value as keyof ShopColors]}`}>

                                {/* Root menu link */}
                                {item.title}

                              </Link>

                              <button
                                className={`
                                ${nav_text_color[dynamicData?.data?.nav_text_color?.value as keyof ShopColors]}`}
                                onClick={() => setDropdownState({ isActive: !DropdownState.isActive, idx: idx })}
                                aria-label="Dropdown menu">

                                {/* Root menu dropdown icon */}
                                {
                                  DropdownState.idx == idx && DropdownState.isActive
                                    ?
                                    (
                                      <FAIcon type="solid" nameIco="fa-chevron-up" customClass={`w-3 h-3 duration-300`} />
                                    )
                                    :
                                    (
                                      <FAIcon type="solid" nameIco="fa-chevron-down" customClass={`w-3 h-3 duration-300`} />
                                    )
                                }
                              </button>

                            </div>
                          )
                          :
                          (
                            <Link
                              href={item.path
                                ? item.path
                                : '#'}
                              className={`${nav_text_color[dynamicData?.data?.nav_text_color?.value as keyof ShopColors]} block`}>

                              {/* Root menu regular link */}
                              {item.title}

                            </Link>
                          )
                      }

                      {/* Dropdown items */}
                      {
                        item.isDropdown && DropdownState.idx == idx && DropdownState.isActive
                          ?
                          (
                            <div className={`${nav_bg_color[dynamicData?.data?.nav_bg_color?.value as keyof ShopColors]}
                            z-40 mt-8 inset-x-0 w-full lg:absolute lg:shadow-lg`}>

                              <ul className='max-w-screen-xl mx-auto items-top gap-6 lg:gap-8 md:py-8 columns-1 md:columns-3 break-inside-avoid-column'>
                                {
                                  Object.keys(item.dropNavs).map((k: string, index: number) =>
                                  {
                                    let nItem = item.dropNavs[k];
                                    return (
                                      <li className="w-full break-inside-avoid mb-8" key={k}>

                                        {/* Dropdown section title */}
                                        <h4 className={`${nav_text_color[dynamicData?.data?.nav_text_color?.value as keyof ShopColors]}
                                        mb-2 font-bold capitalize`}>
                                          {k}
                                        </h4>

                                        {/* Dropdown section links list */}
                                        <ul className="space-y-2">
                                          {nItem.map((navItem: any, idx: number) => (
                                            <li key={idx}>
                                              <Link
                                                href={navItem.path ?? '#'}
                                                className={`${nav_text_color[dynamicData?.data?.nav_text_color?.value as keyof ShopColors]}
                                                items-center`}>

                                                {navItem.title}

                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                      </li>
                                    )
                                  })
                                }
                              </ul>

                            </div>
                          )
                          :
                          ''
                      }
                    </li>
                  )
                })
              }
            </ul>

            {/* Extra Header Button */}
            <div className={`${dynamicData?.data?.extra_button.value === 'true'
              ? 'flex lg:hidden'
              : 'hidden'
              } w-full text-center mb-6 lg:mb-0 lg:mr-6`}>

              <Link
                id="extra-header-button"
                href={dynamicData?.data?.extra_button_link?.value ?? ''}
                className={`w-full inline-flex items-center justify-center text-center rounded px-8 py-3 font-medium duration-300 focus:outline-none focus:ring cursor-pointer
								${getBgColor(dynamicData?.data?.extra_button_color?.value)} ${getTextColor(dynamicData?.data?.nav_bg_color?.value)}`}>

                {dynamicData?.data?.extra_button_text?.value ?? ''}

              </Link>
            </div>

            {/* Mobile contacts */}
            <div className={`${nav_text_color[dynamicData?.data?.nav_text_color?.value as keyof ShopColors]} 
							first-letter:flex flex-wrap justify-between space-y-4 lg:hidden`}>

              <hr className={`${getColor(
                ColorProperty.text,
                dynamicData?.data?.nav_text_color?.value,
                nav_text_color)} mb-6`} />

              <Link href={'tel:' + phone} rel="noopener noreferrer" className="lg:items-center items-top gap-2 flex w-full">
                <FAIcon type="solid" nameIco='fa-mobile-button' customClass="h-4 w-4" />
                <small className="hover:underline focus-visible:underline">{phone}</small>
              </Link>

              <Link href="#" rel="noopener noreferrer" className="lg:items-center items-top gap-2 flex w-full">
                <FAIcon type="solid" nameIco='fa-location-dot' customClass="h-4 w-4" />
                <small className="hover:underline focus-visible:underline">{address + ', ' + city + ', ' + st}</small>
              </Link>

              {/* Hours */}
              <div className="items-start gap-2 flex">
                <FAIcon type="solid" nameIco='fa-calendar' customClass="h-4 w-4" />
                <small className="">
                  {
                    data?.work_hours &&
                    getWorkHoursShort(data?.work_hours).map((day: any, idx: number) => (

                      day.weekday_start == day.weekday_end
                        ?
                        <span
                          key={idx}
                          className="" >

                          <span className="">
                            {day.weekday_start}&ensp;
                          </span>
                          <span className="">
                            {day.time_start}
                          </span>
                          <span>&nbsp;—&nbsp;</span>
                          <span className="">
                            {day.time_end}&ensp;
                          </span>
                          <br />

                        </span>
                        :
                        <span
                          key={idx}
                          className="" >

                          <span className="">
                            {day.weekday_start}&nbsp;—&nbsp;{day.weekday_end}&ensp;
                          </span>
                          <span className="">
                            {day.time_start}
                          </span>
                          <span>&nbsp;—&nbsp;</span>
                          <span className="">
                            {day.time_end}
                          </span>

                        </span>

                    ))
                  }
                </small>
              </div>

            </div>

          </div>

        </div>

      </div>

    </>
  )
}