import FAIcon from "@/base-components/icons";

export default function Checkbox({ id, value, name, required }: { id: string, value: string, name: string, required?: boolean })
{
  return (
    <>
      <input
        id={id}
        type="checkbox"
        value={value}
        name={name}
        required={required}
        className="w-4 h-4 relative peer appearance-none rounded-sm bg-white checked:bg-primary-600 ring-2 ring-primary-600 shrink-0"
      />
      <FAIcon
        type="solid"
        nameIco='fa-check'
        customClass="absolute h-4 w-4 text-white hidden pointer-events-none peer-checked:block"
      />
    </>
  )
}