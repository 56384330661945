'use client';

import FAIcon from "@/base-components/icons";
import { DsxImageClient } from "@/base-components/image";
import { useEffect, useRef, useState } from "react";
import { DsxImageType } from "@/utils/types";
import Link from "next/link";
import { getColor, ColorProperty } from "@/utils/color";

export default function Accordion({ items, images, color }: { items: any, images: DsxImageType[], color: string })
{
	const [activeIdx, setActiveIdx] = useState();
	const contentHeight = useRef<HTMLDivElement>(null);

	useEffect(() =>
	{
		// console.log(contentHeight.current?.clientHeight);
	}, [contentHeight]);

	const handleItemClick = (idx: any) =>
	{
		setActiveIdx((prevIdx) => (prevIdx === idx ? null : idx));
	};

	return (

		<>
			{items?.length > 0
				?
				items?.map((service: any, idx: any) => (

					<li
						key={idx}
						className="flex pt-8 pb-4 pr-8">

						<DsxImageClient dsxImage={images[idx]} />

						<div className="flex flex-wrap w-full">

							{/* Title */}
							<button
								onClick={() => handleItemClick(idx)}
								className="flex items-center justify-between w-full mb-4">

								<h3 className={`${color} text-left`}>

									{service?.title}

								</h3>

								<FAIcon type="solid" nameIco="fa-chevron-down" customClass={`${idx === activeIdx ? "rotate-180" : "rotate-0"} w-3 h-3 duration-300`} />

							</button>

							{/* Paragraph */}
							<div
								key={idx}
								className={`paragraph grid duration-500 overflow-hidden 
								${idx === activeIdx
										? 'grid-rows-[1fr] opacity-100'
										: 'grid-rows-[0fr] opacity-0'}`}>

								<div className={`flex flex-col justify-between overflow-hidden`}>

									{/* Description */}
									<div className="">
										<p className="mb-2">
											{service?.description}
										</p>
									</div>

									{/* CTA button */}
									<div className="">
										<Link
											href={service?.link}
											className="inline-flex py-3 items-center transition focus:outline-none focus:ring focus:ring-white" >

											{service?.link_text}

											<FAIcon type="solid" nameIco="fa-arrow-right" customClass="h-4 ml-2" />

										</Link>
									</div>

								</div>

							</div>

						</div>

					</li>

				))
				:
				''
			}
		</>

	)

}
