"use client"

import { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import { stdComponentDataList } from '@/utils/std-component-data';
import FAIcon from '@/base-components/icons';

//-->dynamicData
let dynamicData = {
	uid: 'cta-right-map',
	data: {

		// Content
		"title": {
			sort: 1,
			name: 'Title',
			type: 'text',
			value: '',
			section: 'content',
		},
		"description_first": {
			sort: 4,
			name: 'Description',
			type: 'textarea',
			value: 'If youre looking for a local auto repair shop you can trust, consider [Shop Name]. For reliable, quality, expert automotive repair and personal, friendly customer service.Since [Year Founded], weve been providing our friends and neighbors throughout[City] with dependable, trustworthy auto repairs.',
			section: 'content',
		},
		"title_second": {
			sort: 1,
			name: 'Second Title',
			type: 'text',
			value: 'A True Alternative To The Dealer',
			section: 'content',
		},
		"description_second": {
			sort: 5,
			name: 'Second Description',
			type: 'textarea',
			value: 'Looking for an affordable alternative to the high prices at your local dealership? [Shop Name] can help. Supported by a team of ASE-Certified technicians who receive ongoing training in auto repair services, we`re able to offer you dealership-quality knowledge and experience, without dealership pricing.',
			section: 'content',
		},
		"button_text": {
			sort: 6,
			name: 'Button Text',
			type: 'text',
			value: 'Request Appointment',
			section: 'content',
		},
		"button_link": {
			sort: 7,
			name: 'Button Link',
			type: 'text',
			value: '#make-appointment',
			section: 'content',
		},

		// UI
		"bg_color": {
			sort: 1,
			name: 'Background Color',
			type: 'color',
			value: '',
			section: 'ui',
		},
		"paddings": stdComponentDataList('slider', 2, 'Top and Bottom Paddings', 'Padding', 'py', 24),
	},
}
//<--dynamicData

export default function Content({ data, config }: any)
{

	if (config && config.uid)
	{
		dynamicData = config;
	}

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: `${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`,
	});

	if (!isLoaded) return <p>Loading...</p>;

	return (

		<section className={`${dynamicData?.data?.paddings?.value
			? dynamicData?.data?.paddings?.value
			: 'py-24'
			} px-4 sm:px-6 xl:px-0 mx-auto max-w-screen-xl`}>

			<div className="grid grid-rows-3 lg:grid-rows-none lg:grid-cols-2 bg-primary-50 rounded-lg">

				{/* Content */}
				<div className="row-span-2 md:row-span-1 px-4 lg:px-8 py-8 lg:py-12">

					{/* Title */}
					<h2 className="text-3xl font-bold sm:text-4xl capitalize mb-4">
						{dynamicData?.data?.title?.value
							? dynamicData?.data?.title?.value
							: 'Your full-service automotive maintenance and repair center!'}
					</h2>

					{/* First paragraph */}
					<p className="text-gray-600 mb-12">
						{dynamicData?.data?.description_first?.value
							? dynamicData?.data?.description_first?.value
							: ''}
					</p>

					{/* Subtitle */}
					<h3 className="text-xl font-bold sm:text-2xl capitalize mb-4">
						{dynamicData?.data?.title_second?.value
							? dynamicData?.data?.title_second?.value
							: ''}
					</h3>

					{/* Second paragraph */}
					<p className="text-gray-600 mb-12">
						{dynamicData?.data?.description_second?.value
							? dynamicData?.data?.description_second?.value
							: ''}
					</p>

					{/* CTA */}
					<div className="flex flex-wrap gap-4 text-center">
						<a href={dynamicData?.data?.button_link?.value
							? dynamicData?.data?.button_link?.value
							: ''}
							className="inline-flex items-center w-full rounded-lg bg-primary-500 px-8 py-3 font-medium text-white shadow hover:bg-primary-700 focus:outline-none focus:ring active:bg-primary-500 sm:w-auto cursor-pointer">
							{dynamicData?.data?.button_text?.value
								? dynamicData?.data?.button_text?.value
								: ''}
							<FAIcon type="solid" nameIco="fa-arrow-right" customClass="h-4 ml-2" />
						</a>
					</div>

				</div>

				<div className="rounded-lg overflow-hidden">
					<Map />
				</div>

			</div>
		</section>
	)
}

function Map()
{
	const center = useMemo(() => ({ lat: 40, lng: -80 }), []);

	return (
		<GoogleMap
			zoom={12}
			center={{ lat: 40, lng: -80 }}
			mapContainerClassName="google-map-container">

			<Marker position={center}></Marker>

		</GoogleMap>
	)
}