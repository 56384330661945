"use client";

import { review } from "@/types/graphql";
import { stdComponentDataList } from "@/utils/std-component-data";

//-->dynamicData
let dynamicData = {
	uid: 'page-reviews-left-cards-masonry',
	adapter: "reviews",
	data: {

		'reviews_number': {
			sort: 0,
			name: 'Reviews Number',
			type: 'number',
			value: 5,
			section: 'content'
		},

		// UI
		"padding_top": stdComponentDataList('slider', 1, 'Top Padding', 'Padding', 'pt', 24),
		"padding_bottom": stdComponentDataList('slider', 2, 'Bottom Padding', 'Padding', 'pb', 32),
	}
}
//<--dynamicData

export default function PageReviews({ data }: any)
{

	// Array of selected reviews
	let selected_reviews = data?.reviews?.filter((review: review) =>
	{
		return review.selected;
	});

	return (

		<section className="py-28">
			<div className="max-w-screen-xl mx-auto px-4 md:px-0">

				<div className="flex justify-between items-start">

					{/* title */}
					<div className="space-y-5">

						<h1 className="mb-6 text-3xl font-bold sm:text-4xl capitalize">
							Reviews
						</h1>

						{/* Divider */}
						<div className="flex mb-6">
							<div className="w-16 h-1 rounded-full bg-primary-500 inline-flex"></div>
						</div>

					</div>

					{/* Tab buttons */}
					<div className="flex pt-2 overflow-x-auto overflow-y-hidden border-b border-neutral-200 whitespace-nowrap">

						<button className="inline-flex items-center h-10 px-4 -mb-px text-sm text-center text-primary-600 bg-transparent border-b-2 border-primary-500 sm:text-base whitespace-nowrap focus:outline-none">
							Reviews from Google
						</button>

						<button className="inline-flex items-center h-10 px-4 -mb-px text-sm text-center text-neutral-700 bg-transparent border-b-2 border-transparent sm:text-base whitespace-nowrap cursor-base focus:outline-none hover:border-neutral-400">
							Certified by AutoVitals
						</button>

					</div>

				</div>

				<ul className="gap-x-8 gap-y-10 mt-16 gap-8 columns-1 sm:columns-2 lg:columns-3 break-inside-avoid-column">
					{
						selected_reviews?.map((review: any, idx: any) =>
						{
							return (

								<li className="rounded-lg overflow-hidden bg-neutral-100 w-full mx-auto mb-12 group sm:max-w-sm relative break-inside-avoid" key={idx}>
									<div className="p-6">

										<blockquote
											className="flex flex-wrap overflow-hidden mx-4 md:mx-0"
											key={idx}>

											<p className="w-full text-neutral-600">
												{review?.content
													? review?.content
													: '—'}
											</p>

											<div className="flex flex-col mt-8 w-full">

												<h3 className="font-bold ml-auto">
													{review.author_name
														? "— " + review.author_name
														: "— Unknown Author"}
												</h3>

												<span className="text-sm text-neutral-400 ml-auto">
													{review.date
														? new Date(review.date).toDateString()
														: '—'}
												</span>

											</div>

										</blockquote>
									</div>

								</li>

							)
						})
					}
				</ul>
			</div>
		</section>

	)
}